import { Space } from "antd";
import SearchForm from "./SearchForm";
import CommonListTable from "./CommonListTable";
import { useEffect, useState } from "react";
import CommonCreateModel from "./Modal/CommonCreateModel";
import { useLocation } from "react-router-dom";
import { exportQuery } from "../../Api/manager";
import { Form, Input, Select, DatePicker, InputNumber } from "antd";
import { Button } from "antd";
import PromptInput from "./Input/PromptInput";
import JsonInput from "./Input/JsonInput";
import { Pagination, Tabs, Slider, Row, Col, List } from "antd";

import { getViewInfo, queryView, queryItem } from "../../Api/manager"
import { useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form"
import CommonForm from "./CommonForm";
import CommonDataList from "./CommonDataList";
// import { useNavigate  }  fro  m "react-router-dom";

const CommonListPage = ({ viewName }) => {
  const params = useParams()

  const [pageViewName, setPageViewName] = useState(viewName)
  const [createModelViewName, setCreateViewName] = useState("")
  const [createModelLoad, setCreateModelLoad] = useState(false)
  const [defaultParams, setDefaultParams] = useState({})
  const [sort, setSort] = useState([])
  const [form] = useForm();


  useEffect(() => {
    if (viewName !== undefined) {
      setPageViewName(viewName)
    } else {
      setPageViewName(params.viewName)
    }
  }, [viewName, params.viewName])


  const queryStrings = new URLSearchParams(useLocation().search)
  const tmpDefaultParams = {}
  queryStrings.forEach((value, key) => {
    tmpDefaultParams[key] = value
  });

  if (Object.keys(defaultParams).length !== Object.keys(tmpDefaultParams).length) {
    setDefaultParams(tmpDefaultParams)
  } else {
    for (const key in tmpDefaultParams) {
      if (defaultParams[key] !== tmpDefaultParams[key]) {
        setDefaultParams(tmpDefaultParams)
        break
      }
    }
  }
  const [colum, setColum] = useState([]);
  const [searchDefine, setSearchDefine] = useState({})
  const [operationItems, setOperationItems] = useState([])
  const [formOperationItems, setFormOperationItems] = useState([])
  const [mergeColum, setMergeColum] = useState("")
  const [viewType, setViewType] = useState("")
  const [props, setProps] = useState({})

  useEffect(() => {
  }, [defaultParams])

  useEffect(() => {
    if (pageViewName !== "" && pageViewName !== undefined) {
      getViewInfo(pageViewName).then((res) => {
        const columns = res.data.items.map((item) => {
          return {
            title: item.lable,
            dataIndex: item.name,
            key: item.name,
            hidden: !item.visible
          };
        });
        setViewType(res.data.view_type)
        setOperationItems(res.data.operation_items)
        setFormOperationItems(res.data.form_operation)
        setColum(columns);
        setMergeColum(res.data.merge_column)
        setSearchDefine(res.data.queryinput);
        setSort([])
        setProps(res.data)
      });
    }
  }, [pageViewName]);



  const renderView = () => {
    if (viewType === "list") {
      return <CommonDataList viewName={pageViewName} searchDefine={searchDefine} formOperationItems={formOperationItems} operationItems={operationItems} colum={colum} mergeColum={mergeColum} defaultParams={defaultParams}></CommonDataList>
    } else if (viewType === "tree") {
      return <div>tree</div>
    } else if (viewType === "detail") {
      return <CommonForm searchDefine={searchDefine} formOperationItems={formOperationItems} viewName={pageViewName} queryParams={defaultParams} {...props}></CommonForm>
    }
  }
  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      {renderView()}
    </Space>
  );
};
export default CommonListPage;
