import { memo, useState, forwardRef, useImperativeHandle } from "react";
import { Input, Tag } from "antd";
import { CloseCircleOutlined ,CheckCircleOutlined} from '@ant-design/icons';
import ModeTag from "./ModeTag";

const GenerationOutput = forwardRef(({ model, temprature, variables, handleClose,handleSelect }, ref) => {
    const [result, setResult] = useState("");

    useImperativeHandle(ref, () => ({
        setResult
    }));

    const handleClick = () => {
        handleClose?.(model, temprature);
    };

    return (
        <div style={{ display: "flex", flexWrap: "wrap", position: "relative" }}>
            <div>
                <CheckCircleOutlined style={{ color: "green" }} onClick={()=>handleSelect?handleSelect(model,temprature):null} />
                <ModeTag model={model} />
                <Tag color="purple">{temprature}</Tag>
                {handleClose && (
                    <CloseCircleOutlined
                        style={{ position: "absolute", top: 0, right: 0 }}
                        color="red"
                        onClick={handleClick}
                    />
                )}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Input.TextArea rows={15} disabled={true} value={result} />
                </div>
            </div>
        </div>
    );
});

export default memo(GenerationOutput);