import request from '../Service/Request';

export const getViewInfo = async (view_name) => {
    return request({
        url: '/api/manager/view-info',
        method: 'post',
        data: { view_name },
    });
}

export const queryView = async (view_name, page, page_size, query,sort) => {
    return request({
        url: '/api/manager/query-view',
        method: 'post',
        data: { view_name, page, page_size, query,sort },
    });
}

export const exportQuery = async (view_name, query) => {
    return request({
        url: '/api/manager/export-query',
        method: 'post',
        responseType: 'blob',
        data: { view_name, query },
    });
}


export const queryItem = async (view_name,params) => {
    return request({
        url: '/api/manager/query-view-data',
        method: 'post',
        data: { view_name,params},
    });
}

export const executeFormOperation = async (view_name,operation,params,required_params) => {
    return request({
        url: '/api/manager/execute-form-operation',
        method: 'post',
        data: { view_name,operation,params,required_params},
    });
}

export const executeOperation = async (view_name,operation,params) => {
    return request({
        url: '/api/manager/execute-operation',
        method: 'post',
        data: { view_name,operation,params},
    });
}