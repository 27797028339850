import { Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { Button, Modal, Form } from "antd";
import ModeTag from "./ModeTag";
import { useState, useEffect, useRef } from "react";
import { Tag, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { RunScript } from "../../../Api/tools";
import GenerationPanel from "./GenerationPanel";
import React, { forwardRef } from "react";

const PromptInput = forwardRef(({ value, onChange, enable = false, model_ref, temprature_ref, models, tips = null, ...pass_params }, ref) => {

    const [variables, setVariables] = useState({})
    const [modalVisible, setModalVisible] = useState(false)
    const [initValue, setInitValue] = useState(value)
    const [initModel, setInitModel] = useState(model_ref)
    const [initTemprature, setInitTemprature] = useState(temprature_ref)
    const [form] = useForm();
    const [script_id, setScriptId] = useState('')
    const [inputVariables, setInputVariables] = useState({});
    useEffect(() => {
        if (pass_params?.script_id) {
            console.log('script_id', pass_params?.script_id)
            setScriptId(pass_params?.script_id)
        }
    }, [pass_params])
    const extractVariables = (text) => {
        const regex = /(?<!\{)\{(?!\{)(.*?)\}(?!\})/g;
        let matches;
        const variables = [];
        while ((matches = regex.exec(text)) !== null && variables.indexOf(matches[1]) === -1) {
            variables.push(matches[1]);
        }
        return variables;
    };
    useEffect(() => {
        const newVariables = extractVariables(value)
        const newVariablesObj = {}

        for (const item of newVariables) {
            if (!variables[item]) {
                newVariablesObj[item] = { name: item, color: randomColor() }
            } else {
                newVariablesObj[item] = variables[item]
            }
        }
        setVariables(newVariablesObj)
    }, [value])

    useEffect(() => {
        if (modalVisible) {
            setInitValue(value)
            setInitModel(model_ref)
            setInitTemprature(temprature_ref)
        }
    }, [modalVisible])
    const handleButtonClick = () => {
        // 测试
        setModalVisible(true)
    };

    const colors = ["green", "blue", "red", "yellow", "purple", "orange", "pink", "brown", "gray", "black"]
    const randomColor = () => {
        const index = Math.floor(Math.random() * colors.length)
        return colors[index]
    }

    const changeInternalValue = (e) => {
        const value = e?.target?.value
        if (!value) return
        onChange?.(value, initModel, initTemprature)
    }

    const handleModalOk = () => {
        setModalVisible(false)
        onChange?.(value, initModel, initTemprature)
    }
    const handleModalCancel = () => {
        setModalVisible(false)
        onChange?.(initValue, initModel, initTemprature)
    }
    const handleSelect = (model, temprature) => {
        setInitModel(model)
        setInitTemprature(temprature)
        onChange?.(value, model, temprature)
        setModalVisible(false)
    }
    return (

        <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
                <ModeTag model={model_ref} />
                <Tag color="purple">{temprature_ref}</Tag>
                {tips && (!enable) && <div style={{ marginLeft: "10px" }}><Tooltip title={tips}><InfoCircleOutlined /></Tooltip></div>}
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Input.TextArea rows={10} value={value} onChange={changeInternalValue} disabled={!enable} />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span>变量：</span>
                {
                    Object.keys(variables).map(key => {
                        return <Tag key={key} color={variables[key].color}>{key}</Tag>
                    })
                }
            </div>
            <Button onClick={handleButtonClick} disabled={!enable}>调试</Button>
            <Modal size="large" width={1000} ref={ref} title="调试" open={modalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
                <Form form={form}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ flex: 6, marginRight: "10px" }}>
                            <Input.TextArea rows={15} value={value} onChange={changeInternalValue} />
                            <div style={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
                                {Object.keys(variables).map(key => {
                                    return <Form.Item key={key} label={key} name={key}><Input value={inputVariables[key]} onChange={(e) => {
                                        setInputVariables(prevValues => ({ ...prevValues, [key]: e.target.value }));
                                    }} /></Form.Item>
                                })}
                            </div>
                        </div>
                        <div style={{ flex: 6, display: "flex" }}>
                            <GenerationPanel models={models} prompt={value} model_ref={model_ref} temprature={temprature_ref} variables={inputVariables} script_id={script_id} handleSelect={handleSelect} onSelectModel={setInitModel} onSetTemprature={setInitTemprature} />
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    );
});

export default PromptInput;