import { memo } from "react";
import SearchForm from "./SearchForm";
import CommonListTable from "./CommonListTable";
import { useState, useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { queryView, exportQuery, executeOperation } from "../../Api/manager";
import { Pagination } from "antd";
import CommonCreateModel from "./Modal/CommonCreateModel";
import { useNavigate } from "react-router-dom";
const CommonDataList = ({ viewName, searchDefine, formOperationItems, operationItems, colum, defaultParams, mergeColum }) => {


    const [createModelViewName, setCreateViewName] = useState("")
    const [createModelLoad, setCreateModelLoad] = useState(false)
    const [sort, setSort] = useState([])
    const [searchParams, setSearchParams] = useState({})
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(mergeColum === "" || mergeColum === undefined ? 10 : 100)
    const [currentPage, setCurrentPage] = useState(1)
    const [contactInfoList, setContactInfoList] = useState([])
    const [form] = useForm();
    const navigate = useNavigate();


    const onSearch = (searchParams) => {
        setSearchParams(searchParams)
        setCurrentPage(1);
    };
    const onReset = (searchParams) => {
        setCurrentPage(1);
        searchParams(searchParams)
    };
    const onClickOperation = (operation) => {
        if (operation.operation_view !== ""){
            var navigateParams = {}
            if(operation.operation_map){
                for (const key in operation.operation_map) {
                    navigateParams[key] = defaultParams[key]
                }
            }
            console.log('navigateParams',navigateParams)
            if (operation.operation_type === "add_in_new_page"){
                navigate(`/${operation.operation_view}`,{state:navigateParams})
            }else if (operation.operation_type === "add_in_modal"){
                setCreateViewName(operation.operation_view)
                setCreateModelLoad(true)
            }
        }
       

    }
    const onExport = () => {
        const params = {
            ...searchParams,
            ...defaultParams
        }
        console.log(params)
        exportQuery(viewName, params)
    }

    const onSortChange = (sorter) => {
        if (sorter.order !== undefined) {
            // add to sort
            const sorterAdd = { column: sorter.field, order: sorter.order }
            const lastSort = sort.filter(item => item.column !== sorter.field)
            console.log("lastSort", lastSort)
            setSort([...lastSort, sorterAdd])
        } else {
            // remove from sort
            setSort(sort.filter(item => item.column !== sorter.field))
        }
    }
    const onPaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    const handleFormOperation = (operation, params) => {
        executeOperation(viewName, operation, params).then(res => {
            queryData()
        })
    }
    useEffect(() => {
        queryData()
    }, [pageSize, currentPage, searchParams, colum, sort]);

    const queryData = () => {
        setLoading(true);
        const params = {
            ...searchParams,
            ...defaultParams
        }
        queryView(viewName, currentPage, pageSize, params, sort)
            .then((res) => {
                setTotal(res.data.total);
                if (mergeColum !== "") {
                    const mergeData = {}
                    res.data.items.forEach(item => {
                        var isAdd = true
                        if (item[mergeColum] !== "") {
                            for (const key in mergeData) {
                                if (item[mergeColum].toString().indexOf(key.toString()) === 0 && item[mergeColum].length > key.length) {
                                    mergeData[key].children.push(item)
                                    isAdd = false
                                }
                            }
                            if (isAdd) {
                                mergeData[item[mergeColum]] = { children: [], ...item }
                            }
                        }
                    })
                    console.log(mergeData)
                    setContactInfoList(Object.values(mergeData).sort((a, b) => a[mergeColum].localeCompare(b[mergeColum])))
                } else {
                    setContactInfoList(res.data.items);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const renderForm = () => {
        return <div>  <SearchForm onSearch={onSearch} onReset={onReset} inputs={searchDefine} operations={formOperationItems} onClickOperation={onClickOperation} onExport={onExport}></SearchForm>
            <CommonListTable
                operationItems={operationItems}
                dataColumns={colum}
                dataSource={contactInfoList}
                loading={loading}
                onSortChange={onSortChange}
                handleFormOperation={handleFormOperation}
            ></CommonListTable>
            <Pagination pageSize={pageSize} onChange={onPaginationChange} current={currentPage} total={total} ></Pagination>
            <CommonCreateModel open={false} onCancel={() => { }} onOk={() => { }} viewName={createModelViewName} load={createModelLoad}></CommonCreateModel>
   
        </div>
    }
    return renderForm()
}
export default memo(CommonDataList)
