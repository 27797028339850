import { SSE } from "sse.js";
import Cookies from "js-cookie";
import store from "store";
import request from "../Service/Request";

const url = (process.env.REACT_APP_BASEURL || "") + "/api/tools/script-debug";

export const RunScript = (script_id,script_prompt,script_temprature,script_variables,script_model,script_type,onMessage) => {

  var source = new SSE(url + "?token=" + store.get("token"), {
    headers: { "Content-Type": "application/json" ,'X-API-MODE':'admin'},
    payload: JSON.stringify({
        script_id,script_prompt,script_temprature,script_variables,script_model,script_type
    }),
  });
  source.onmessage = (event) => {
    try {
      var response = JSON.parse(event.data);
      if (onMessage) {
        onMessage(response);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  source.onerror = (event) => {
    console.log("连接错误");
    console.log(event);
    // this.searchBoll = true;
  };
  source.onclose = (event) => {
    console.log("会话完成断开连接");
    // this.searchBoll = true;
    console.log(event);
  };
  source.onopen = (event) => {
    console.log("连接成功");
    console.log(event);
  };
  source.close = () => {
    console.log("主动断开连接");
  };
  source.stream();
  return source;
};


