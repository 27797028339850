import { memo, useState, useRef } from "react";

import { Select, Slider, Button } from "antd";
import GenerationOutput from "./GenerationOutput";
import { RunScript } from "../../../Api/tools"

const GenerationPanel = ({ models, model_ref, prompt, temprature, variables, script_id, handleSelect, onSelectModel = null, onSetTemprature = null }) => {
    const [model, setModel] = useState("")
    const [temprature_ref, setTemprature] = useState(temprature)
    const [modelSetting, setModelSetting] = useState([{ model: model_ref, temprature: temprature }])
    const generationOutputRefs = useRef([])
    const [debuging, setDebuging] = useState(false)
    const handleButtonClick = () => {
        setModelSetting([...modelSetting, {
            model: model,
            temprature: temprature_ref
        }])
    }

    const handleClose = (model, temprature) => {
        let newModelSetting = []
        for (const item of modelSetting) {
            if (item.model !== model || item.temprature !== temprature) {
                newModelSetting.push(item)
            }
        }
        setModelSetting(newModelSetting)
    }

    const handleButtonDebugClick = () => {
        setDebuging(true)
        const promises = modelSetting.map((item, index) => {
            let tmpText = "";
            return new Promise((resolve) => {
                RunScript(script_id, prompt, temprature_ref, variables, item.model, "", (e) => {
                    tmpText += e.text;
                    if (generationOutputRefs.current[index]) {
                        generationOutputRefs.current[index].setResult(tmpText);
                    }
                    resolve();
                });
            });
        });

        Promise.all(promises).then(() => {
            console.log("All scripts have been run.");
            setDebuging(false)
        });

    }
    const selectModel = (value) => {
        modelSetting[modelSetting.length - 1].model = value
        setModelSetting(modelSetting)
        setModel(value)
        onSelectModel?.(value)
    }
    const selectTemprature = (value) => {
        modelSetting[modelSetting.length - 1].temprature = value
        setModelSetting(modelSetting)
        setTemprature(value)
        onSetTemprature?.(value)
    }
    const renderContent = () => {
        return <div style={{ display: "flex", flex: 6, flexDirection: "column" }}>
            <div style={{ display: "flex", flex: 3, flexDirection: "column" }}>
                <Select options={models} value={model} onChange={selectModel} style={{ width: "100%" }} />
                <Slider value={temprature_ref} onChange={selectTemprature} step={0.1} min={0} max={2} style={{ flex: 1, width: "100%" }} />
                <Button onClick={handleButtonClick}>增加生成模型</Button>
                <Button onClick={handleButtonDebugClick} loading={debuging}>调试</Button>
            </div>
            <div style={{ display: 'flex', flex: 10, flexDirection: 'row', overflowX: 'auto', width: "100%", justifyContent: "space-between" }}>
                {
                    modelSetting.map((item, index) => {
                        return (
                            <div key={index} style={{
                                display: 'flex',
                                flex: 5,
                                flexShrink: 0,
                                marginRight: '10px', minWidth: '200px', width: '200px'
                            }}>
                                <GenerationOutput
                                    ref={el => generationOutputRefs.current[index] = el}
                                    model={item.model}
                                    temprature={item.temprature}
                                    variables={variables}
                                    handleClose={index === 0 ? null : handleClose}
                                    handleSelect={handleSelect ? handleSelect : null}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    }
    return renderContent()
}
export default memo(GenerationPanel)