import { Input } from "antd";
import ReactJson from 'react-json-view';

const JsonInput = ({ value, onChange }) => {
  let jsonValue;
  try {
    jsonValue = JSON.parse(value);
  } catch (e) {
    jsonValue = null;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      {/* <p>json</p> */}
      <Input.TextArea rows={10} value={value} onChange={(e) => {
        onChange(e.target.value)
      }} style={{ flex: 1 }} />
      {jsonValue && <ReactJson src={jsonValue} style={{ flex: 1, marginLeft: '10px' }} />}
    </div>
  );
}

export default JsonInput